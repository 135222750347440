@import 'react-toastify/dist/ReactToastify.css';
@import './animation.scss';

.Toastify__toast-container {
  width: 100%;

  .Toastify__toast-body {
    word-wrap: break-word;
    word-break: break-all;
  }
}

.Toastify__toast-theme--dark {
  background: #00DA70;
  color: black;
  padding: 0 15px;
}

.Toastify__close-button {
  margin-top: 14px;
  color: black;
  cursor: pointer;

  svg {
    display: inline-block;
    width: 24px;
    height: 24px;
  }
}

.Toastify__toast-container--top-center {
  top: 80px;
}

.Toastify__toast-body > div:last-child {
  max-height: 240px;
  overflow-y: auto;
}

.element-toast-error {
  min-height: 0;
  padding: 10px 16px;
  color: #fff;
  font-size: 14px;
  background: rgba(231, 76, 67, 0.8);
  border-radius: 0;

  .Toastify__toast-body {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    word-wrap: break-word;
  }
}

.element-toast-error-in {
  display: flex;
}

.element-toast-error-out {
  display: none;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    right: 0;
  }
}
