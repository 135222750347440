@use '../../scss/' as *;
.bgm-img {
    position: fixed;
    right: 56px;
    top: 120px;
    width: 40px;
    z-index: 1;

    @include mobile {
        right: 20px;
        top: 120px;
    }
}