@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

@keyframes nft-img-spin {
    0% {
        --rotate: 0deg;
    }
    100% {
        --rotate: 360deg;
    }
}

.nft-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nft-img-box {
    max-width: 100%;
    height: auto;
    transform: scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    --card-height: 450px;
    // --card-width: calc(var(--card-height) / 1.5);
    --card-width: 450px;

    @media screen and (max-width: 500px) {
        & {
            --card-height: calc(100vw - 30px);
            --card-width: calc(100vw - 30px);
        }
    }
    
    .card {
        background: #000;
        // background: #232120;
        width: var(--card-width);
        height: var(--card-height);
        padding: 3px;
        position: relative;
        border-radius: 6px;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        text-align: center;
        display: flex;
        font-size: 1.5em;
        color: #fff;
        cursor: pointer;
        font-family: cursive;

        &::before {
            content: "";
            width: calc(100% + 14px);
            height: calc(100% + 14px);
            border-radius: 8px;
            background-image: linear-gradient(
            var(--rotate)
            , #FFFFFF, #79FF99, #00B02C);
            position: absolute;
            z-index: -1;
            top: -7px;
            left: -7px;
            animation: nft-img-spin 2.5s linear infinite;
        }

        &::after {
            position: absolute;
            content: "";
            top: calc(var(--card-height) / 6);
            left: 0;
            right: 0;
            z-index: -1;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            transform: scale(1);
            filter: blur(80px);
            background-image: linear-gradient(
            var(--rotate)
            , #00B02C, #006C1B, #00488A);
            opacity: 1;
            transition: opacity .5s;
            animation: nft-img-spin 2.5s linear infinite;
        }

        &:hover {
            .card-h {
                color: rgb(250 250 250 / 100%);
                transition: color 1s;
            }
        }

        .card-img {
            width: 100%;
            // height: 100%;
            // margin-bottom: 40px;
            // transform: scale(0.7);
        }

        .card-h {
            position: absolute;
            color: rgb(250 250 250 / 0);
            bottom: 40px;
            font-family: "IBM Plex Mono", monospace;
            font-weight: 700;
            font-size: 0.8em;
        }
    }

}

@media only screen and (max-width:815px) {
    .nft-container {
        height: auto;
        // margin: 80px 0 !important;
    }

    .nft-img-box {
        margin-bottom: 80px;
    }

    .item-details {
        padding-left: 0;
        margin: 10px 0;
    }
  }