
@import url('./css-fonts.css');

$font: 'Lato', sans-serif;
$big: 'Montserrat', sans-serif;

$red: #EF2F3C;
$white: #F6F4F3;
$blue: #276FBF;
$dark: #183059;
$yellow: #F0A202;

$star: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);

@keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
}

@keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
}

@keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
}

@keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
}
  
.claim {
    position: relative;
    display: grid;

    .claim-container {
        position: relative;
        margin: auto;
        overflow: hidden;
        width: 650px;
        height: 440px;
    }

    h1 {
        font-family: $font;
        text-align: center;
        margin-top: 2em;
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 5px;
        color: $white;
    }

    #timer {
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-family: $font;
        font-size: .7em;
        letter-spacing: 5px;
        margin-top: 25%;
    }

    .days, .hours, .minutes, .seconds {
        display: inline-block;
        padding: 20px;
        width: 140px;
        height: 110px;
        margin: 4px;
        border-radius: 5px;
    }

    .days {
        background: $red;   
    }

    .hours {
        background: $white;
        color: $dark;
    }

    .minutes {
        background: $blue;
    }

    .seconds {
        background: $yellow;
    }

    .numbers {
        font-family: $big;
        color: $dark;
        font-size: 42px;
        line-height: 54px;
    }

    .white {
        position: absolute;
        background: $white;
        height: 85px;
        width: 75px;
        left: 30%;
        top: 2%;

        .triangle {
            border-bottom: 14px solid $white;
        }
        .string {
            background: $white;
            border: 1px solid $white;
        }
    }

    .red {
        position: absolute;
        background: $red;
        left: 18%;
        top: 9%;
        height: 65px;
        width: 70px;

        .triangle {
            border-bottom: 14px solid $red;
        }
        .string {
            background: $red;
            border: 1px solid $red;
        }
    }

    .blue {
        position: absolute;
        background: $blue;
        height: 80px;
        width: 80px;
        left: 60%;
        top: 5%;

        .triangle {
            border-bottom: 14px solid $blue;
        }
        .string {
            background: $blue;
            border: 1px solid $blue;
        }
    }

    .balloon {
        border: 1px solid #000;
        border-radius: 50% 50% 50% 50%/ 40% 40% 60% 60%;
        z-index: 2;
    }

    .eye {
        position: absolute;
        width: 7px;
        height: 7px;
        top: 40%;
        left: 22%;
        background: #000;
        border-radius: 50%;
        &:after {
            content: '';
            left: 35px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #000;
            position: absolute;
        }
    }

    .mouth {
        position:absolute;
        top: 45%;
        left: 43%;
        width: 7px;
        height: 7px;
        border-radius: 50%;
    }

    .happy {
        border: 2px solid;
        border-color: transparent #000 #000 transparent;
        transform: rotate(45deg);
    }

    .triangle {
        position: absolute;
        left: 40%;
        bottom: -10%;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
    }

    .string {
        position: absolute;
        height: 70px;
        width: 1px;
        left: 48%;
        top: 100%;
        z-index: -1;
    }

    .star {
        width: 20px;
        height: 20px;
        background: $white;
        -webkit-clip-path: $star;
        clip-path: $star;
    }

    .star-red {
        width: 30px;
        height: 30px;
        margin-left: 51px;
        margin-top: -5px;
        background: $red;
        -webkit-clip-path: $star;
        clip-path: $star;
    }

    .claim-btn {
        position: relative;
        display: inline-block;
        width: 122px;
        padding: 10px 20px;
        color: #00DA70;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        overflow: hidden;
        transition: .5s;
        margin: 60px auto 40px;
        margin-left: calc(50% - 62px);
        letter-spacing: 4px;
        text-align: center;
        cursor: pointer;

        &:hover {
            background: #00DA70;
            color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 5px #00DA70,
                        0 0 25px #00DA70,
                        0 0 50px #00DA70,
                        0 0 100px #00DA70;
              
        }
        
        span {
            position: absolute;
            display: block;

            &:nth-child(1) {
                top: 0;
                left: -100%;
                width: 100%;
                height: 2px;
                background: linear-gradient(90deg, transparent, #00DA70);
                animation: btn-anim1 1s linear infinite;
            }
            &:nth-child(2) {
                top: -100%;
                right: 0;
                width: 2px;
                height: 100%;
                background: linear-gradient(180deg, transparent, #00DA70);
                animation: btn-anim2 1s linear infinite;
                animation-delay: .25s
            }
            &:nth-child(3) {
                bottom: 0;
                right: -100%;
                width: 100%;
                height: 2px;
                background: linear-gradient(270deg, transparent, #00DA70);
                animation: btn-anim3 1s linear infinite;
                animation-delay: .5s
            }
            &:nth-child(4) {
                bottom: -100%;
                left: 0;
                width: 2px;
                height: 100%;
                background: linear-gradient(360deg, transparent, #00DA70);
                animation: btn-anim4 1s linear infinite;
                animation-delay: .75s
            }
        }
    }
}


@media only screen and (max-width:815px) {
    .claim {
        .claim-container {
            width: 100%;
            height: 100%;
        }

        .string {
            height: 60px;
        }

        #timer {
            margin-top: 45%;
        }
    }
}