@use '../' as *;

.breadcrumbs {
    text-align: center;
    h3 {
        text-transform: uppercase;
    }
}
.tf-title {
    margin-bottom: 15px;
    text-align: center;
    .sub-title {
        line-height: 1.6;
        color: $bg-2;
        text-transform: uppercase;
        font-family: $font-main2;
        margin-bottom: 8px;
        letter-spacing: 0.6px;
    }
    .title {
        text-transform: uppercase;
    }
    &.st2 {
        text-align: left;
    }
}
.split-line {
    width: 100%;
    height: 2px;
    background: #363c42;
    margin: 15px 0 20px;
}
.post {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    .image {
        position: relative;
        margin-bottom: 32px;
        .tag {
            @include flex(center,center);
            position: absolute;
            top: 20px;
            left: 30%;
            font-family: $font-main;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            background-color: $bg-2;
            font-weight: $font-w7;
            height: 32px;
            padding: 5px 12px;
            color: $white;
        }
    }
    .title {
        text-transform: capitalize;
        letter-spacing: 0;
        margin-bottom: 9px;
    }
    .meta-post {
        @include flex2;
        margin-bottom:  8px;
        li {
            margin-right: 10px;
            padding-right: 20px;
            position: relative;
            &::after {
                content: "|";
                position: absolute;
                top: 0;
                right: 0;
            }
        &:last-child::after{
            display: none;
        }
        span {
            margin-right: 11px;
        }
        }
        li
        a {
            color: $white;
            &:hover {
                color: $color-hover;
            }
        }
        
    }
    p {
        letter-spacing: 0;
    }
    .read-more {
        @include font-16;
        text-transform: uppercase;
        font-weight: $font-w7;
        font-family: $font-main;
        color: $white;
        letter-spacing: 0.6px;
        &:hover {
            color: $color-hover;
        }
    }
    &.inner-post {
        margin-top: 40px;
        @include flex(center,flex-start);
        .image {
            position: relative;
            margin-bottom: 0;
            min-width: 440px;
            .tag {
                top: 20px;
                right: 15px;
                left: auto;
            }
        }
        .content-post {
            padding-left: 40px;
            padding-right: 15px;
            .meta-post {
                li {
                    margin-right: 9px;
                    padding-right: 18px;
                    font-size: 15px;
                    line-height: 1.73;
                    span {
                        margin-right: 9px;
                        
                    }
                }   
            }
            .title {
                margin-bottom: 8px
            }
        }

        @include mobile {
            flex-wrap: wrap;
            .image {
                min-width: 100%;
            }

            .content-post {
                padding-left: 0;
                padding-top: 20px;
            }
        }
    }
    &.detail {
        .image {
            .tag {
                position: relative;
                left: 0;
                top: 0;
            }
        }
    }
}

.themesflat-pagination {
    ul {
        @include flex2;
        li {
            padding: 0;
            margin-right: 8px;
            a {
                @include flex(center,center);
                @include font-16;
                font-weight: $font-w7;
                font-family: $font-main;
                border: 1px solid $color-main2;
                background: transparent;
                width: 36px;
                height: 36px;
                color: $white;
                padding: 0;
                &.active,
                &:hover {
                    background: $bg-2;
                    border-color: $bg-2;
                }
            }
        }
    }
}

.grid {
    .article {
        width: 73.5%;
    }
    #sidebar {
        width: 26.5%;
        padding-left: 37px;
    }

    @include tablet {
        .article {
            width: 100%;
        }
        #sidebar {
            width: 100%;
            padding: 50px 15px 0;
        }
    }
}

// .post-details {
    .detail .container{
        width: 960px;

        @include desktop-width {
            width: 100%;
        }
    }
    .detail {
        .article {
            .post,
            .post-details,
            .content-details {
                p,li {
                    font-size: 20px;
                    line-height: 1.6;
                    letter-spacing: 0;
                }
            }
        }
        }
    .detail {
        .post {
            margin-bottom: 33px;
            .image {
                margin-bottom: 21px;
                min-height: 32px;
                .tag.st2 {
                    position: absolute;
                    text-transform: capitalize;
                    font-weight: 400;
                    top: 0;
                    left: 0;
                    font-family: $font-main2;
                }  
            }
            .title {
                margin-bottom: 18px;
            } 
            .meta-post {
                li {
                    font-size: 17px;
                    line-height: 1.64;
                }
            }
        }
    }
    .post-details {
        margin-bottom: 40px;
        .page-title {
            padding: 108px 0 0 0;
        }
        p {
            margin-bottom: 12px;
        }
        .image {
            margin-top: 40px;

            img {
                width: 100%;
            }
        }
    }
    .content-details {
        margin-bottom: 32px;
        h6 {
            letter-spacing: 0;
            margin-bottom: 14px;
            text-transform: capitalize;
        }
        p {
            margin-bottom: 20px;
            &.st2 {
                margin-bottom: 12px;
            }

            a {
                color: #00DA70;
                word-break: break-word;
            }
        }
        .list {
            margin-bottom: 20px;
            li {
                letter-spacing: 0;
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;
                svg {
                    position: absolute;
                    top: 13px;
                    left: 0;
                }
            }
        }
        &.mb-41 {
            margin-bottom: 41px;
        }
    }
// }
.wrap-widget {
    margin-bottom: 59px;
    @include flex(center,space-between);
    .widget {
        &.widget-tag {
            @include flex(center,flex-start);
            
            ul li {
                margin-right: 9px;
                font-size: 17px;
                line-height: 1.64;
                span {
                    font-size: 16px;
                    line-height: 22px;
                    color: $color-st2;
                    font-weight: 400;  
                }
                a {
                    font-family: $font-main2;
                    font-size: 17px;
                    line-height: 1.64;
                }
            }
        }
        .title-widget {
            font-size: 16px;
            line-height: 1.5;
            color: $white;
            margin-right: 5px;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            margin-bottom: 0;
        }
        &.widget-social {
            margin-top: -3px;
            .title-widget {
                margin-right: 11px
            }
            @include flex(center,center);
            h4 {
                margin-right: -8px
            }
            ul {
               @include flex2;
               li {
                   margin-left: 21px;
                   margin-right: 0;
                   font-size: 17px;
                   line-height: 1.64;
                    a {
                        -webkit-mask-image:none;
                        mask-image:none;
                        width: auto;
                        height: auto;
                        background: none;
                        color: $white;
                        &:hover {
                            color: $color-hover;
                        }
                        i {
                            font-size: 14px
                        }
                    } 
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.widget-tag ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-box-details {
    @include flex(flex-start,space-between);
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 16px 0 12px;
    .previous,
    .next {
        width: 50%;
        a  {
            &.st {
                font-size: 14px;
                line-height: 1.57;
                font-weight:$font-w7;
                font-family: $font-main;
                margin-bottom: 3px;
                text-transform: uppercase;
                color: $color-main2;
            }
            &:hover {
                color: $bg-2;
            }
        }
        h6 {
            font-size: 20px;
            line-height: 1.3;
            color: $white;
        }
    }
    .previous {
        padding-right: 70px;
        position: relative;
        h6 {
            letter-spacing: 0;
        }
        &::after {
            position: absolute;
            right: 0;
            top: 13px;
            width: 1px;
            height: 60px;
            background: rgba(255,255,255,0.1);
            content: "";
        }
    }
    .next {
        text-align: right;
        padding-left: 95px;
        h6 {
            letter-spacing: 0;
        }
    }
}


.sl-post2 {
    .post-item {
        .content-item {
            .content {
                .h7 {
                    margin-bottom: 17px;
                    &.st {
                        padding-right: 60px;
                    }
                }
            }
        }
    }
}


/* animation slider */
.box-slider .content-box .wrap-btn,
.box-slider .content-box h1,
.box-slider .content-box h2,
.box-slider .content-box .sub-title {
    transform: translateY(200px);
    opacity: 0;
}

.box-slider .image {
    transform: translateX(200px);
    opacity: 0;
}

.swiper-slide-active .box-slider .image,
.swiper-slide-active .box-slider .content-box .wrap-btn,
.swiper-slide-active .box-slider .content-box h1,
.swiper-slide-active .box-slider .content-box h2,
.swiper-slide-active .box-slider .content-box .sub-title {
    opacity           : 1;
    visibility        : visible;
    -webkit-transition: transform 800ms ease, opacity 800ms ease;
    -moz-transition   : transform 800ms ease, opacity 800ms ease;
    -ms-transition    : transform 800ms ease, opacity 800ms ease;
    -o-transition     : transform 800ms ease, opacity 800ms ease;
    transition        : transform 800ms ease, opacity 800ms ease;
    transition-delay  : 1000ms;
}
.swiper-slide-active .box-slider .image {
    transform: translateX(0px);
}
.swiper-slide-active .box-slider .content-box .wrap-btn,
.swiper-slide-active .box-slider .content-box h1,
.swiper-slide-active .box-slider .content-box h2,
.swiper-slide-active .box-slider .content-box .sub-title {
    transform: translateY(0px);
}
.swiper-slide-active .box-slider .image,
.swiper-slide-active .box-slider .content-box h2,
.swiper-slide-active .box-slider .content-box h1 {
    transition-delay: 400ms;
}

.swiper-slide-active .box-slider .content-box .sub-title {
    transition-delay: 500ms;
}

.swiper-slide-active .box-slider .content-box .wrap-btn {
    transition-delay: 600ms;
}





.sl-team.swiper-container {
    @include showshadow;
    padding-left: 70px;
}

.wrap-team {
    margin-right: -307px;
    padding-left: 70px;

    @include tablet {
        margin-right: 0;
        padding-left: 30px;
    }
    @include mobile {
        margin-top: 50px;
        padding-left: 0;
    }
}


.item-parner {
    padding: 0 70px;
    margin-top: 67px;
    .sl-parner {
        margin-bottom: 74px;
    }
    .image {
        img {
            @include transition5;
            transform: scale(1);
        }
    }
}


.featured-box {
    @include flex2;
    flex-wrap: wrap;
    margin-left: -$mb-30;
    .img-box {
        width: calc(25% - $mb-30);
        margin-left: $mb-30;
        margin-bottom: 32px;
    }
}

.image-faq {
    position: relative;
    img {
        mix-blend-mode: luminosity;
    }
}

.wrap-fx {
    .image-faq {
        width: 45%;
    }
    .content-faq {
        width: 55%;
        padding-left: 36px;
    }

    @include tablet {
        .image-faq{
            width: 100%;
            img {
                width: 100%;
            }
        }
        .content-faq{
            width: 100%;
            padding-left: 0;
            padding-top: 50px;
            .flat-accordion {
                max-width: 100%;
            }
        }
    }
}


.gallery {
    width: 100%;
    padding-left: 70px;
    padding-top: 2px;
    .button-fx {
        margin-top: 28px;
        margin-bottom: 24px;
        .btn-prev-team, 
        .btn-next-team {
            min-width: 32px;
            min-height: 32px;
            height: 32px;
            &.tf-button {
                .boder-fade {
                    border: 1px solid $bg-2;
                }
                .effect {
                    &::before,
                    &::after {
                        width: 1px;
                        background: $bg-2;
                    }
                }
                &::before,
                &::after {
                        height: 1px;
                        background: $bg-2;
                }
            }
            i {
                font-size: 20px;
            }
        }
        .btn-prev-team {
            margin-right: 16px;
        }
    }
    &-slider {
        width: 100%;
        height: auto;
        margin: 0 0 10px 0;

        .swiper-slide {
            .team-box {
                @include flex(flex-start,flex-start);
                background: none;
                border: none;
                &::after,
                &::before {
                    display: none;
                }
                .content {
                    padding: 38px 0 0 30px;
                    h5 {
                        margin-bottom: 6px;
                    }
                    p {
                        margin-bottom: 16px;
                    }
                    .desc {
                        color: $white;
                        font-size: 17px;
                        line-height: 1.64;
                        text-transform: none;
                        margin-bottom: 27px;
                    }
    
                }

                @include mobile {
                    flex-wrap: wrap;

                    .content {
                        padding: 38px 0 0 0;
                    }
                }
            }
        }
    }
    
    &-thumbs {
        .swiper-slide {
            width: 150px;
            height: 150px;
            text-align: left;
            overflow: hidden;
            .image {
                &::before {
                    @include transition5;
                }
                img {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    max-width: 150px;
                    max-height: 150px;
                    background: #052012;
                }
            }
            
            &.swiper-slide-active {
                .image {
                    position: relative;
                    &::before {
                        border: 1.5px solid $bg-2;
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: $w100;
                        height: $w100;
                        z-index: 999;
                    }
                    
                }
            }

        }
    }
    .gallery-thumbs {
        margin-right: -260px;
        margin-top: 22px;
        .image {
            &::before {
                @include transition3;
            }
            &:hover {
                cursor: pointer;
                &::before {
                    border: 1.5px solid $bg-2;
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: $w100;
                    height: $w100;
                    max-width: 150px;
                    max-height: 150px;
                    z-index: 999;
                }
            }
        }

        @include mobile {
            margin-right: 0;
        }

    }

    @include desktop-width {
        padding-left: 0;
        padding-top: 60px;
    }
}
.gallery-slider {
    .swiper-slide {
        .team-box {
            box-shadow: none;
            img {
                border: 1.5px solid $bg-2;
                max-width: 330px;
                max-height: 330px;
                width: 330px;
                height: 330px;
            }
        }
    }
    .swiper-slide-active {
        .team-box {
            box-shadow: none;
            img {
                border: 1.5px solid $bg-2;
            }
        }
    }
}
.seclect-box {
    @include flex(flex-start,flex-end);
    .dropdown {
        margin-left: 20px;
        button {
            @include flex(center,flex-start);
            max-width: 240px;
            min-width: 240px;
            padding: 12px 15px 12px 20px;
            height: 52px;
            color: $color-main1;
            text-decoration: none;
            border: 1px solid rgba(255,255,255,0.2);
            background: transparent !important;
            border-radius: 0;
            &:after {
                @include topcenter;
                font-family: 'Font Awesome 5 Pro';
                font-size: 14px;
                font-weight: 400;
                content: "\f078";
                position: absolute;
                color: #B9B9BF;
                right: 20px;
                border: none;
            }
            &:focus {
                box-shadow: none !important;
                border-color: $color-hover !important;
            }
        }
        li span {
            @include transition3;
            font-size: 15px;
            line-height: 24px;
            color             : $white;
        }
        li {
            @include transition5;
            background: transparent;
            width: 100%;
            padding: 0 16px 0 20px;
            line-height: 45px;
            height: 45px;
            color: $white;
        }
        li.active,
        li:hover {
            background: $bg-2;
        }
    }
}

.seclect-box .dropdown-menu {
        max-width: 240px;
        min-width: 240px;
        
        width: 100%;
        left: 0;
        height: auto;
        padding: 0;
        top: 0;
        background: $bgr-body;
        border-radius: 0;
        transform: translate(0px, 52px) !important;
        border: none;
        box-shadow: 0 0px 24px 0 rgb(34 183 143 / 50%);
        .dropdown-item {
            padding: 0;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: $w100;
            height: $w100;
            border-bottom: 1px solid rgba(255,255,255,0.2);     
            border-left: 1px solid rgba(255,255,255,0.2);  
            border-right: 1px solid rgba(255,255,255,0.2); 
            z-index: -1;
        }
        &:hover {
            cursor: pointer;
        }
    // &.show {
    //     a {
    //         background: transparent;
    //         width: 100%;
    //         padding: 9px 10px 8px 16px;
    //         @include transition3;
    //         font-size: 15px;
    //         line-height: 24px;
    //         color: $white;
    //         &:active,
    //         &:hover {
    //             background: $bg-2;
    //         }
    //     }
    // }
}

.fl-item {
    display: none;
}

.nft-container .desc {
    position: relative;
    z-index: 1;
    color: $color-main1;
    // font-size: 17px;
    line-height: 1.6;
    margin-bottom: 2rem
}

.item-details {
    // padding-left: 70px;
    h6 {
        color: $white;
        margin-bottom: 28px;
    }
    .sub {
        margin-bottom: 16px;
        color: $white;
    }
    .action {
        @include flex(center,space-between);
        margin-bottom: 27px;
        .right-box,
        .left-box {
            @include flex2;
            a {
                @include flex(center,center);
                font-size: 14px;
                line-height: 22px;
                color: $white;
                svg {
                    path {
                        @include transition3;
                    }
                }
                &:hover {
                    color: $bg-2;
                    svg {
                       path {
                        fill: $bg-2;
                       }
                    }
                }
            }
        }
        .left-box {
            a {
                margin-right: 14px;
                width: 75px;
                height: 36px;
                border-radius: 40px;
                svg {
                    margin-right: 7px;
                }
            }
        }
        .right-box {
            a {
                margin-left: 13px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }
    }
    .tf-button {
        @include flex(center,center);
        max-width: 100%;
        margin-bottom: 40px;
        margin-top: 29px;
        height: 52px;
        // svg {
        //     margin-right: 10px;
        //     margin-left: 3px;
        //     margin-top: -5px;
        // }
        // &:hover {
        //     svg {
        //         path {
        //             fill: $white;
        //         }
        //     }
        // }

        @include mobile {
            height: auto;
            text-align: center;
            padding: 12px 15px;
        }
    }   
    .list-product {
        @include flex2;
        margin-left: -16px;
        flex-wrap: wrap;
        .box {
            margin-bottom: 16px;
            width: calc(33.3333% - 16px);
            margin-left: 16px;
            &.corner-box {
                padding: 20px 22px 10px;
                background: $color-3;
                &::before,
                &::after {
                    background: $color-3;
                }
                &::before {
                    width: calc(110% - 3rem);
                }
                &::after {
                    height: calc(120% - 3rem);
                }
            }
            p {
                font-size: 15px;
                line-height: 26px;
                color: $color-main1;
                margin-bottom: 4px;
            }
            .h7 {
                color: $white;
            }
            &:hover {
                box-shadow: $box-shadow;
            }
        }
    }  
    
    .participate {
        .flat-accordion {
            position: relative;
            margin-bottom: 40px;

            .check-item {
                display: flex;
                align-items: center;
            }
            
            .checked-icon {
                display: inline-flex;
                margin: 0 12px;

                &.position {
                    position: absolute;
                    right: 20px;
                    top: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    z-index: 2;
                    margin: 0;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .traded-num {
                font-size: 15px;
            }

            .accordion {
                .accordion-button {
                    padding-left: 36px;

                    &::after {
                        left: 0;
                        right: unset;
                        font-size: 23px;
                    }

                    .tips-icon {
                        width: 18px;
                        margin-left: 9px;
                    }
                }
            }
        }

        .tf-button {
            width: 100%;
            margin-top: 0;
            background: #00DA70;
            color: #000;

            .circle-loading-svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    
    .apply {
        .flat-accordion {
            position: relative;
            margin-bottom: 30px;

            .check-item {
                display: flex;
                align-items: center;
            }

            .accordion {
                .accordion-button {
                    &::after {
                        display: none;
                    }

                    .tips-icon {
                        width: 18px;
                        margin-left: 9px;
                    }
                }
            }
        }

        .tf-button {
            width: 100%;
            margin-bottom: 0;
            background: #00DA70;
            color: #000;

            .circle-loading-svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .check-allowList {
        .tf-button {
            background: #3498db;
            width: 100%;
            color: #000;
            margin-bottom: 0;

            .circle-loading-svg {
                width: 24px;
                height: 24px;
            }

            &.green {
                background: #00DA70;
            }
        }
    }
}

.modal-backdrop.show {
    opacity: 0.85;
}

.modal-content {
    background: #1b1d24;
}

.tx-await-modal-text {
    display: flex;
    align-items: center;
    padding: 20px 0 0;
    color: #00DA70;
    font-size: 24px;

    .circle-loading-svg {
        width: 26px;
        height: 26px;
        margin-right: 12px;
    }

    @include mobile {
        padding: 12px 0 0;
        font-size: 20px;

        .circle-loading-svg {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }
}

.pass-apply-modal {
    .modal-dialog {
        width: 698px;

        @include mobile {
            width: calc(100% - 30px);
            margin: 0 15px;
        }
    }

    .modal-content {
        background: #1b1d24;
        overflow: hidden;
    }

    .modal-header {
        border: none;
        padding: 2em 2em;

        @include mobile {
            padding: 1em;
        }

        p {
            width: 100%;
            color: #fff;
            font-weight: bold;
            margin: 0;
        }
    }

    .modal-body {
        padding: 0 2em 2em;

        table,td,th {
            border: 1px solid #00DA70;
            text-align: center;
        }

        table {
            width: 100%;

            .icon {
                width: 66px;
                height: 66px;
                padding-top: 16px;
                vertical-align: top;

                &.personal {
                    padding-top: 11px;
                }
            }

            input,textarea {
                border: none;
                outline: none;
                resize: none;
                color: #fff;
            }
            textarea {
                height: 128px;
            }
        }

        .tf-button {
            background: #00DA70;
            color: #000;
            margin-top: 2em;
            width: 100%;
            z-index: 1;
        }
    }
}

.check-allowList-modal {
    overflow: hidden;

    .modal-dialog {
        width: 698px;

        @include mobile {
            width: calc(100% - 30px);
            margin: 0 15px;
        }
    }

    .modal-content {
        background: #1b1d24;
        overflow: hidden;

        .check-allowList-modal-bg {
            position: absolute;
            left: -154px;
            top: 182px;
            filter: blur(10px);
            width: 120%;
            height: 120px;
            transform: rotate(36deg);
            background: linear-gradient(90deg, rgba(72, 190, 98, 0.71) 0%, rgba(24, 43, 28, 0) 100%);
            z-index: 0;
        }

        .check-allowList-modal-content {
            position: relative;
            margin: 0 40px;
            padding: 50px 42px;
            border: 4px solid;
            border-image: linear-gradient(180deg, #a9ffbb 0%, #51cf61 0%, rgba(6,41,14,0.00) 100%, rgba(6,41,14,0.00) 0%, rgba(15,37,18,0.00) 100%) 4 4;
            border-radius: 4px;
            overflow: hidden;
            background: #1b1d24;

            @include mobile {
                margin: 0 20px;
                padding: 32px 20px;
            }

            .check-allowList-modal-content-bg {
                position: absolute;
                left: -90px;
                top: 110px;
                filter: blur(20px);
                width: 120%;
                height: 160px;
                transform: rotate(35deg);
                background: linear-gradient(90deg, rgba(72, 190, 98, 0.71) 0%, rgba(24, 43, 28, 0) 100%);
                z-index: 0;
            }

            .check-allowList-modal-content-text {
                position: relative;
                margin-bottom: 200px;
                font-size: 28px;
                line-height: 1.8em;
                font-weight: normal;

                @include mobile {
                    font-size: 20px;
                }
            }

            .tf-button {
                width: 100%;
                z-index: 1;
            }

            .check-allowList-modal-content-bg2 {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 280px;
                height: 340px;
                background-size: cover;
                background-image: url('../../assets/images/background/elemetas-bg.svg');
                background-repeat: no-repeat;
                opacity: 0.5;

                @include mobile {
                    width: 160px;
                    height: 240px;
                }
            }
        }

        .modal-header {
            border: none;
            padding: 2em 2em;

            @include mobile {
                padding: 1em;
            }

            .h6 {
                width: 100%;
                color: #fff;
                text-align: center;
                font-weight: bold;
                margin: 0;
            }
        }

        .modal-body {
            padding: 0;
        }

        .btn-close {
            font-size: 22px;
            color: #00DA70;
        }
    }
}

.check-allowList-modal2 {
    overflow: hidden;

    .modal-dialog {
        width: 698px;

        @include mobile {
            width: calc(100% - 30px);
            margin: 0 15px;
        }
    }

    .modal-content {
        background: #1b1d24;
        overflow: hidden;

        .check-allowList-modal-bg {
            position: absolute;
            right: 0px;
            top: 0;
            z-index: 0;
        }

        .check-allowList-modal-content {
            position: relative;
            margin: 0 40px;
            padding: 0 42px 50px;
            overflow: hidden;

            @include mobile {
                margin: 0 20px;
                padding: 32px 20px;
            }

            .check-allowList-modal-content-text {
                position: relative;
                font-size: 28px;
                line-height: 1.8em;
                font-weight: normal;

                @include mobile {
                    font-size: 20px;
                }
            }

            .tf-button {
                width: 100%;
                z-index: 1;
            }

            .check-allowList-modal-content-bg2 {
                width: 146px;
                height: 200px;
                margin: 38px auto;
                background-size: cover;
                background-image: url('../../assets/images/common/nftcard.svg');
                background-repeat: no-repeat;
            }
        }

        .modal-header {
            border: none;
            padding: 2em 2em;

            @include mobile {
                padding: 1em;
            }

            .h6 {
                width: 100%;
                color: #fff;
                text-align: center;
                font-weight: bold;
                margin: 0;
            }
        }

        .modal-body {
            padding: 0;
        }

        .btn-close {
            font-size: 22px;
            color: #00DA70;
        }
    }
}

@keyframes confettiRain {
    0% {
      opacity: 1;
      margin-top: -100vh;
      margin-left: -200px;
    } 
    
    100% {
      opacity: 1;
      margin-top: 100vh;
      margin-left: 200px;
    }
}

.confetti {
    opacity: 0;
    position: absolute;
    width: 12px;
    height: 16px;
    animation: confettiRain 5s;
}

.form-contact {
    fieldset {
        margin-bottom: 20px;
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; 
        }
    }
    input[type=text], 
    input[type=password], 
    input[type=datetime], 
    input[type=datetime-local], 
    input[type=date], 
    input[type=month], 
    input[type=time], 
    input[type=week], 
    input[type=number], 
    input[type=email], 
    input[type=url], 
    input[type=search], 
    input[type=tel], 
    input[type=color] {
        @include transition3;
        height: 52px;
        line-height: 1.73;
        padding: 13px 15px 13px 18px;
        font-size: 15px;
        color: $color-main1;
        &:focus {
            border-color: $color-hover;
        }
    }
    textarea {
        height: 110px;
        font-size: 15px;
        line-height: 1.73;
        padding: 20px 15px 13px 18px;
    }
    button {
        height: 66px;
        margin-top: 11px;
        padding: 0 40px;
    }
    input[type=text]::placeholder, 
    input[type=password]::placeholder, 
    input[type=datetime]::placeholder, 
    input[type=datetime-local]::placeholder, 
    input[type=date]::placeholder, 
    input[type=month]::placeholder, 
    input[type=time]::placeholder, 
    input[type=week]::placeholder, 
    input[type=number]::placeholder, 
    input[type=email]::placeholder, 
    input[type=url]::placeholder, 
    input[type=search]::placeholder, 
    input[type=tel]::placeholder, 
    input[type=color]::placeholder,
    textarea::placeholder {
        color: $color-main1;
    }
}

.post-details {
    .post-item {
        .content-item {
            bottom: -2px;
        }
    }
}

.flat-alert.msg-success {
    color: $bg-2;
}

.box-wallet-inner {
    @include flex2;
    margin-left: -30px;
    flex-wrap: wrap;
    .sc-box {
        width: calc(33.333% - 30px);
        margin-left: 30px;
        padding: 30px 20px;
        -webkit-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        -moz-box-shadow: 0px 3px 16px 0px #2F536D1F;
        -webkit-box-shadow: 0px 3px 16px 0px #2f536d1f;
        box-shadow: 0px 3px 16px 0px #2f536d1f;
        margin-bottom: 30px;
        background: var(--primary-color);
    }
}
.roadmap , .tf-team , .blog {
    .swiper-button-prev , .swiper-button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        min-height: 48px;
        background: transparent;
        border: 1px solid #00DA70;
        margin-right: 15px;
        padding: 0;
        
        position: absolute;
    
        &.active,
        &:hover {
            background: $bg-2;
        }
        &::after {
            font-size: 30px;
            font-weight: 100;
            color: #fff;
        }
    }
    
}

.tf-team {
    .swiper-button-prev , .swiper-button-next {
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        &::after {
            font-size: 20px;
        }
    }
}
.roadmap {
    .swiper-button-prev , .swiper-button-next {
        top: 107.5%;
    }
    
    .swiper-button-prev {
        left: 46.4%;
    }
    .swiper-button-next {
        right: 45.5%;
    }

    @include tablet  {
        .swiper-button-prev , .swiper-button-next{
            display: none;
        }
    }
}

.tf-team {
    .swiper-button-prev , .swiper-button-next {
        top: 80%;
    }
}

.tf-team.gallery {
    .team-box {
        padding-bottom: 80px;
    }
    .swiper-button-prev , .swiper-button-next {
        top: 97%;
    }
    
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 87%;

        @include mobile {
            right: 75%;
        }
    }
}

.video {
    position: relative;
    .wrap-video {
        a {
            @include flex(center,center);
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            padding-left: 5px;
            top: 41.5%;
            @include leftcenter;
            background: #052012;
        }
    }       
}
.page-title {
    &.p404 {
        padding: 232px 0 239px;
        h1 {
            font-size: 300px;
            line-height: 1;
            color: $bg-2;
            margin-bottom: 1px;
        }
        h2 {
            text-transform: uppercase;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 39px;
        }
        .tf-button {
            height: 66px;
            line-height: 62px;
            padding: 0 40px;
        }
    }
}

.comimg-soon {
    text-align: center;
    background-size: cover;
    padding: 212px 0 167px !important;
    .header__logo {
        margin-bottom: 26px;
    }
    .sub-heading {
        margin-bottom: 40px;
        letter-spacing: 10.5px;
        text-transform: uppercase;
        font-weight: $font-w7;
        color: $color-main1;
        margin-left: 45px;
        font-size: 20px;
        line-height: 1.5;
        font-family: $font-main;
        span {
            margin: 0 28px 0 0;
        }
    }
    h3 {
        color: $white;
        letter-spacing: 55.6px;
        text-transform: uppercase;
        margin-bottom: 49px;
        margin-left: 56px;
        span {
            margin: 0 38px;
        }
    }
    .countdown__timer {
        @include flex(center,center);
        margin: 0 auto;
    }
    .countdown__item {
        margin: 0 20px;
        width: 80px;
        height: 80px;
        position: relative;
        text-align: center;
        padding: 0;
        line-height: 94px;
        background: #15293a;
        &::after,
        &::before {
            background: #15293a;
        }
        &::before {
            width: calc(130% - 3rem);
        }
        &::after {
            height: calc(130% - 3rem);
        }
        &:hover {
            box-shadow: $box-shadow;
        }
        span {
            font-size: 44px;
            line-height: 1.27;
            font-family: $font-main;
            color: $white;
            font-weight: 700;
        }
    }
    .featured-countdown {
        margin-bottom: 40px;
        ul {
            @include flex(center,center);
            padding-left: 18px;
            margin-top: 4px;
            li {
                margin-right: 60px;
                font-size: 20px;
                line-height: 1.5;
                color: $white;
                font-family: 'Urbanist', sans-serif;
                &:nth-child(2) {
                    padding-left: 13px;
                }
                &:nth-child(4) {
                    margin-right: 0;
                    margin-left: -12px;
                }
            }
        }
    }

    .widget-social li {
        margin: 0 19px 0 20px;
        a {
            background: none;
            width: auto;
            height: auto;
            --path:0;
            &:hover {
                background: none;
            }
    }
    }
    form {
        max-width: 600px;
        margin-bottom: 23px;
        margin-left: auto;
        margin-right: auto;
        
        &#subscribe-form {
            input {
                border-color: $bg-2;
                width: 100%;
                color: $color-main1;
                font-size: 15px;
                line-height: 1.73;
                &::placeholder {
                    font-size: 15px;
                    line-height: 1.73;
                    color: $color-main1;
                }
            }
            button {
                max-width: 148px;
                position: absolute;
                height: 48px;
                right: 8px;
                top: 8px;
                text-transform: uppercase;
                width: 148px;
                letter-spacing: 1px;
            }
        }
    }
}

.tf-contact {
    .contact-details {
        overflow: hidden;
        padding-left: 150px;
        .adress {
            margin-bottom: 43px;
            &.wrap-fx {
                .location,
                .mail {
                    width: 50%;
                    ul {
                        li {
                            font-size: 20px;
                            line-height: 1.6;
                        }
                    }
                }
                .location {
                    padding-right: 32px;
                }
                .mail {
                    padding-left: 30px;
                }
            }
            h6 {
                margin-bottom: 3px;
            }
        }
        .flat-map {
            height: 430px;
        }

        @include tablet {
            padding-top: 50px;
            padding-left: 0;
        }
    }
    
    .container {
        overflow: hidden;
    }
}

.team-detail {
    .info-detail {
        padding-left: 0;
        .sub {
            font-size: 20px;
            line-height: 1.6;
            color: $color-hover;
            font-weight: 700;
            letter-spacing: 1px;
            margin-bottom: 9px;
        }
        .name {
            text-transform: uppercase;
            margin-bottom: 28px;
        }
        .box {
            margin-bottom: 30px;
            padding-right: 110px;
            .h7 {
                text-transform: capitalize;
                margin-bottom: 12px;
            }
            p {
                margin-bottom: 12px;
            }
            &.contact {
                margin-bottom: 28px;
                p {
                    padding-right: 15px;
                    padding-right: 90px;
                    margin-bottom: 0;
                }
            }
        }
        .social {
            @include flex2;
            li {
                margin-right: 17px;
                a {
                    @include flex(center,center);
                    width: 40px;
                    height: 40px;
                    background: rgba(20, 194, 163, 0.4);
                    border-radius: 8px;
                    &:hover {
                        background: #00DA70;
                    }
                }
            }
        }
    }
}

.tf-video {
    padding-bottom: 0 !important;
}
.page-item-details .tf-item-details  {
    padding-bottom: 0 ;

    .tf-item-details-left {
        padding-right: 50px;
        
        @include mobile {
            padding: 0 15px;
        }
    }

    .container {
        flex-direction: column;
        justify-content: unset;
        padding-top: 80px;
        height: auto;

        @include mobile {
            overflow: hidden;
        }

        .container-row {
            margin-bottom: 120px;

            .row {
                justify-content: space-between;
                margin: 0 0 32px;
                
                .box-text {
                    // width: calc(50% - 16px);
                }
            }

            .attributes {
                display: flex;
                flex-wrap: wrap;
                margin: 30px -15px 0;
                padding: 0;

                .attributes-item-box {
                    position: relative;
                    background: $bgr-body;

                    .box-text {
                        @include mobile {
                            margin: 0;
                        }
                    }
                }

                .col-xl-4 {
                    padding: 15px;

                    @include mobile {
                        width: 50%;
                    }
                }

                .box-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                    padding: 12px 20px;
                    
                    .image {
                        width: 40px;
                        height: 40px;
                    }

                    .h8 {
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }

            .price-box {
                padding: 28px 24px;
                margin-bottom: 56px;
                background-color: #1A1D24;

                .heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 600;
                }

                .tf-button {
                    margin-top: 0;
                    margin-bottom: 0;

                    .boder-fade {
                        z-index: 0;
                    }
                }
            }
        }
    }

    .mint-detail {
        .quantity-bar {
            .quantity-bar-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .quantity-bar-box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: #424e63;
                height: 4px;
                margin-top: 6px;
                width: 100%;

                .quantity-bar-active {
                    height: 4px;
                    background: #00DA70;
                }
            }
        }

        .phase-box {
            padding: 12px 24px 20px;
            background-color: #1A1D24;

            @include mobile {
                padding: 12px 12px 20px;
            }

            .heading {
                margin-bottom: 16px;
                font-size: 28px;

                @include mobile {
                    margin-bottom: 8px;
                }
            }

            .current-phase {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include mobile {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .current-phase-price {
                    display: flex;
                    align-items: baseline;

                    @include mobile {
                        margin-bottom: 8px;
                    }

                    h6 {
                        margin: 0 0 0 9px;
                        font-size: 26px;
                    }
                }

                .featured-countdown {
                    .countdown-wrapper {
                        display: flex;

                        .time-section {
                            display: flex;
                            align-items: baseline;
                            margin-left: 15px;
                            color: #fff;

                            @include mobile {
                                margin-left: 0;
                                margin-right: 8px;
                            }

                            .time {
                                font-size: 26px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }

            .price-box {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding: 0;
                margin: 0;

                .price-box-input {
                    display: flex;
                    align-items: center;
                }

                .price-box-count {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }
                
                .number-input {
                    display: flex;
                    align-items: center;

                    .number-input-button {
                        padding: 0 5px;
                        font-size: 28px;
                        color: #fff;
                        cursor: pointer;
                        user-select: none;

                        &:hover {
                            color: #00DA70;
                        }

                        &.disabled {
                            cursor: not-allowed;
                            color: #B9B9BF;
                        }
                    }

                    .number-input-num {
                        font-size: 16px;
                        height: 40px;
                        letter-spacing: -.02px;
                        line-height: 24px;
                        margin: 0 8px;
                        padding: 9px 4px;
                        text-align: center;
                        width: 80px;
                        color: #fff;
                        font-weight: bold;
                        font-size: 18px;
                        border: 1px solid rgb(185 185 191 / 50%);

                        @include mobile {
                            width: 56px;
                            margin: 0 4px;
                        }
                    }

                    input[type='number'] {
                        appearance: textfield;
                    
                        &::-webkit-inner-spin-button {
                        /* stylelint-disable */
                        -webkit-appearance: none;
                        }
                    }
                }

                .max-mint {
                    margin-left: 20px;

                    @include mobile {
                        margin-left: 10px;
                    }
                }

                .total-price {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    // margin-left: 40px;
                    
                    @include mobile {
                        // margin-left: 16px;
                    }

                    &.auto {
                        height: auto;
                    }

                    .total-num {
                        font-size: 24px;
                        font-weight: bold;
                        color: #fff;
                        margin: 0 0 0 9px;
                    }
                }
            }

            .tf-button {
                margin-bottom: 0;

                .boder-fade {
                    z-index: 0;
                }
            }
        }

        .mint-btn-box {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .mint-btn {
            background: #00DA70;
            color: #000;
            cursor: pointer;
            
            .circle-loading-svg {
                width: 24px;
                height: 24px;
            }

            &.view,&.sold-out {
                background: initial;
                color: #fff;
                width: calc(50% - 15px);
            }

            &.sold-out {
                @include mobile {
                    width: calc(40% - 4px);
                }
            }

            &.view {
                @include mobile {
                    width: calc(60% - 4px);
                }
            }
        }
    }

    .mint-schedule-titlt {
        font-size: 26px;
    }

    .mint-schedule-item {
        margin-bottom: 30px;

        .h7 {
            display: flex;
            align-items: center;
            font-size: 22px;

            .tag {
                padding: 4px 8px;
                margin-left: 12px;
                font-size: 12px;
                font-weight: bold;

                &.Ongoing {
                    background: rgba(32,168,134,0.15);
                    color: #00DA70;
                }

                &.Ended {
                    background: rgba(32,168,134,0.15);
                    color: #838B92;
                }
            }
        }
    }
}

#tooltip-participate {
    .tooltip-inner {
        max-width: 560px;
        text-align: left;
    }
}

.tooltip-inner {
    padding: 0.75rem 1rem;
    border-radius: 0;
}

.page-item-details .tf-project {
    padding: 66px 0 80px;
}

// .circle-loading-svg {
//     width: 100%;
//     height: 100%;
//     animation: circle-loading-svg-circle 1.2s linear infinite;
//     stroke: currentColor;
//     stroke-dasharray: 80px, 200px;
//     stroke-dashoffset: 0;
// }

// @keyframes circle-loading-svg-circle {
//     0% {
//       stroke-dasharray: 100px, 200px;
//       stroke-dashoffset: 0;
//     }
  
//     50% {
//       stroke-dasharray: 100px, 200px;
//       stroke-dashoffset: -15px;
//     }
  
//     100% {
//       stroke-dasharray: 100px, 200px;
//       stroke-dashoffset: -125px;
//     }
// }

.circle-loading-svg {
    position: relative;
    display: flex;
    width: 26px;
    height: 26px;
    color: #fff;

    &::before, &::after{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 50%;
        content: '';
    }

    &::before {
        border: 3px solid rgba(248, 247, 250, 0.3);
    }

    &::after {
        border-top-color: rgba(248, 247, 250, 0.3);
        border-style: solid;
        border-width: 3px;
        animation: buttonSpin 0.6s infinite linear;
    }

    @keyframes buttonSpin {
        from {
          transform: rotate(0);
        }
    
        to {
          transform: rotate(360deg);
        }
      }
}

.distribution {
    padding: 80px 0;

    .submit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #00DA70;
        padding: 18px 24px;
        margin-bottom: 32px;
    }

    .table {
        color: #fff;

        &>:not(:first-child) {
            border-color: #00DA70;
        }

        .tr {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            padding: 18px 24px;

            &.theader {
                padding: 12px 24px;
                background: #1A1D24;
            }

            &>:not(:first-child) {
                text-align: right;
            }
        }
    }
}