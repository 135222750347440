@use '../../scss/' as *;

.partner .swiper {
    margin-bottom: 74px;

    &:nth-last-child(1) {
        margin-bottom: 31px;
    }

    .swiper-wrapper {
        justify-content: space-between;
        align-items: center;
    }
}

.line-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 20px;

    &.center {
        grid-template-columns: repeat(1, 1fr);

        .image {
            height: auto;
            border: none;

            img {
                position: unset;
                transform: scale(1.2);
            }
        }
    }
    
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 140px;
        box-sizing: border-box;
        border: 1px solid #272b32;

        img {
            position: absolute;
            transform: scale(0.2);
        }
    }
} 

.image {
    .element {
        height: 40px;
    }

    .order {
        height: 30px;
    }

    .order2 {
        height: 30px;
    }

    .order4 {
        height: 32px;
    }

    .order3 {
        height: auto;
    }
}

.home-1 .partner{
    background: $bg-3;
}
.home-2 .partner{
    background: $bg-main;

    padding: 80px 0 60px;
}

.home-3 .partner {
    background: $bg-main;
    padding: 86px 0 50px;
}

.about .partner {
    padding: 53px 0 49px;
}

.about-v2 .partner {
    padding: 80px 0 50px;
}

@media only screen and (max-width: 480px) {
    .line-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .image {
            height: 80px;
    
            img {
                transform: scale(0.25);
            }
        }
    }
}