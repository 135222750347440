.ecosystem {
    .ecosystem-accordion {
        width: 100%;
        overflow: hidden;
    }
    .ecosystem-accordion-content {
        display: flex;
        width: max-content;
        animation: scrollX 30s linear infinite;

        &:not(:last-child) {
            margin-bottom: 44px;
        }

        &:nth-child(even) {
            animation-name: scrollX2;
        }

        &:hover {
            animation-play-state: paused;
        }

        @keyframes scrollX {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-50%);
            }
        }

        @keyframes scrollX2 {
            0% {
                transform: translateX(-50%);
            }
            100% {
                transform: translateX(0%);
            }
        }
    }

    .image-item {
        position: relative;
        width: 100px;
        height: 100px;
        margin-right: 40px;
        border-radius: 50%;
        overflow: hidden;

        .image {
            width: 100%;
            height: 100%;
        }

        .text-box {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            background: rgba($color: #000000, $alpha: 0.5);
            font-family: "Chakra Petch", sans-serif;
            font-size: 11px;
            line-height: 28px;
            font-weight: bold;
        }
    }   
}